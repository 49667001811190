import React from "react";

function Richtext({ align = "left", type = "normal", tableLeft = false, children }) {
  return (
    <div
      className={`richtext align-${align} type-${type} ${tableLeft ? "table-left" : ""}`}
    >
      {children}
    </div>
  );
}

export default Richtext;
