import React from "react";
import Banner from "../components/Banner";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Article from "../components/Article";
import Spacer from "../components/Spacer";
import slugify from "../util/slugify";
import "../util/pardotAnalytics";
import useAnalytics from "../hooks/useAnalytics";

function News({ data, bannerImage }) {
  const posts_per_page = 6;
  const [totalPages, setTotalPages] = React.useState(
    Math.ceil(data.news.edges.length / posts_per_page)
  );
  const [allArticlesLoaded, setAllArticlesLoaded] = React.useState(false);
  const [totalArticles, setTotalArticles] = React.useState(data.news.edges.length);
  const [currentCategory, setCurrentCategory] = React.useState("");
  const [currentResources, setCurrentResources] = React.useState(data.news.edges);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagedArticles, setPagedArticles] = React.useState(data.news.edges);
  const [jsOn, setJsOn] = React.useState(false);

  let categories = data.news.edges
    .filter(({ node }) => typeof node.frontmatter.category !== "undefined")
    .map(({ node }) => node.frontmatter.category);

  categories = new Set([...categories]);
  categories = [...categories];

  React.useEffect(() => {
    setJsOn(true);
  }, []);
  
  useAnalytics();

  React.useEffect(() => {
    if (currentCategory.length === 0) {
      setCurrentResources(data.news.edges);
    } else {
      let filtered = data.news.edges.filter(
        ({ node }) =>
          typeof node.frontmatter.category !== "undefined" &&
          node.frontmatter.category === currentCategory
      );
      const all_loaded = filtered.length <= posts_per_page;
      setAllArticlesLoaded(all_loaded);
      setCurrentResources(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  React.useEffect(() => {
    setTotalArticles(currentResources.length);
    setTotalPages(Math.ceil(currentResources.length / posts_per_page));
  }, [currentResources]);

  React.useEffect(() => {
    const paged_articles = [...currentResources].splice(0, posts_per_page * currentPage);
    setPagedArticles(paged_articles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResources, currentPage]);

  React.useEffect(() => {
    const all_loaded = totalArticles === pagedArticles.length;
    setAllArticlesLoaded(all_loaded);
  }, [pagedArticles, totalArticles]);

  function loadMore() {
    const all_loaded = totalArticles === pagedArticles.length;
    const current_page = all_loaded ? totalPages : currentPage + 1;
    setAllArticlesLoaded(all_loaded);
    setCurrentPage(current_page);
  }

  return (
    <div id="news">
      <Banner
        title="News"
        filterOptions={categories}
        filterSet={setCurrentCategory}
        jsOn={jsOn}
        image={bannerImage}
      />
      <Section>
        <Container>
          <Row>
            {pagedArticles.map(({ node }) => (
              <Column key={slugify(node.frontmatter.title)} size={4}>
                <Article data={node} />
              </Column>
            ))}
          </Row>
          {!allArticlesLoaded && jsOn === true && (
            <>
              <Spacer />
              <button className="button solid" onClick={loadMore}>
                Load more
              </button>
            </>
          )}
        </Container>
      </Section>
    </div>
  );
}

export default News;
