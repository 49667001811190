import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { graphql } from "gatsby";
import inlineImagesReducer from "../util/inlineImagesReducer";

import News from "../layouts/News";

function NewsPage({ data, pageContext }) {
  const components = {
    News,
  };
  const inlineImages = inlineImagesReducer(data.mdx.frontmatter.inlineImages);
  return (
    <MDXProvider components={components}>
      <MDXRenderer pageContext={pageContext} data={data} inlineImages={inlineImages}>
        {data.mdx.body}
      </MDXRenderer>
    </MDXProvider>
  );
}

export default NewsPage;

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        meta_title
        meta_description
        meta_image
        excerpt
        inlineImages {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
    news: allMdx(
      filter: { frontmatter: { slug: { regex: "^/news?(..)/" } } }
      sort: { order: DESC, fields: fields___timestamp }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date
            category
            excerpt
            meta_title
            meta_description
            meta_image
          }
        }
      }
    }
  }
`;
