import React from "react";
import Richtext from "./Richtext";
import Container from "./Container";
import slugify from "../util/slugify";
import genID from "../util/genID";

function Wrapper({ isCallToAction, children, className, id }) {
  if (isCallToAction) {
    return (
      <aside id={id} className={`section ${className}`}>
        {children}
      </aside>
    );
  } else {
    return (
      <section id={id} className={`section ${className}`}>
        {children}
      </section>
    );
  }
}

function Section({
  bgcolor = "white",
  preheader,
  title,
  children,
  isCallToAction = false,
  id,
  hide,
}) {
  const headers = [];
  if (!id && typeof title !== "undefined") id = slugify(title);
  if (typeof id === "undefined") id = genID();
  if (preheader)
    headers.push(<em key={`section-preheader-${preheader}`}>{preheader}</em>);
  if (title) headers.push(<h2 key={`section-title-${title}`}>{title}</h2>);

  return (
    <Wrapper
      isCallToAction={isCallToAction}
      className={`bgcolor-${bgcolor} hide-${hide}`}
      id={id}
    >
      {headers.length > 0 ? (
        <header>
          <Container size="sm">
            <Richtext align="center">{headers}</Richtext>
          </Container>
        </header>
      ) : (
        <></>
      )}
      {children}
    </Wrapper>
  );
}

export default Section;
