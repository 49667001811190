import { Link } from "gatsby";
import React from "react";

function Article({ data }) {
  return (
    <Link to={data.frontmatter.slug} className="article-excerpt">
      <article>
        <header>
          <h2>{data.frontmatter.title}</h2>
          <time>{data.frontmatter.date}</time>
        </header>
        <p>{data.frontmatter.excerpt}</p>
      </article>
    </Link>
  );
}

export default Article;
